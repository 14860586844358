import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import BigButton from "../../components/buy-equipment/big-buttons"
import WrappingList from "../../components/buy-equipment/wrapping-list"

import styles from "../buy-equipment.module.css"

const PackagingEquipment = () => {
  const data = useStaticQuery(graphql`
    query {
      allCategory(
        sort: { fields: [name], order: ASC }
        filter: { parentCategorySlug: { eq: "/packaging-equipment" } }
      ) {
        nodes {
          name
          slug
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title={`Used Pharmaceutical Equipment & Biotech Instruments For Sale`}
        description={`Surplus Solutions carries a wide variety of used pharmaceutical lab, packaging, and processing equipment. Contact us today for more information on how we can help solve your equipment needs.`}
        />
      <div className="row columns">
        <ul className="nav-buy">
          <li>
            <Link to="/buy-equipment/lab-equipment">Lab Equipment</Link>
          </li>
          <li className="selected">
            <Link to="/buy-equipment/packaging-equipment">
              Packaging Equipment
            </Link>
          </li>
          <li>
            <Link to="/buy-equipment/processing-equipment">
              Processing Equipment
            </Link>
          </li>
        </ul>

        <h1 className="headings mt2 clearfix">Packaging Equipment</h1>
        <p>
          Surplus Solutions offers a comprehensive and frequently changing
          selection of used industrial packaging and wrapping equipment
          including both primary and secondary packaging lines. Whether you are
          looking for blister packaging, cappers, case packaging, check
          weighers, conveyors, labelers, liquid and powder fillers, metal
          detectors, or wrappers, Surplus Solutions can help you.
        </p>
        <p>
          We can fill just about every packaging need for the food,
          pharmaceutical, chemical, and cosmetics industries. Browse our
          inventory of used packaging equipment below. If you don&apos;t see the
          product you need, contact us and let our team find it for you!
        </p>
      </div>

      <WrappingList containerClassName={styles.container} data={data.allCategory.nodes} slugPrefix={"/catalog"} />

      <BigButton />
    </Layout>
  )
}

export default PackagingEquipment
